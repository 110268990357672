:root {
  --cyan: #0dcaf0;
  --darkcyan: #0f83ad;
  --blue: #002b41;
  --darkblue: #001d2c;

  --timing: 265ms;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #001d2c;
  color: white;
  font-family: Arial, sans-serif;
  line-height: 1.3;
  min-width: fit-content;
  margin: 0;
  padding: 0;
}

/* UTILITIES */

.intro a,
.jobs a,
.contacts a,
.btn {
  color: var(--cyan);
  text-decoration: none;
  transition: all 0.5s ease-out;
}

.intro a:hover,
.jobs a:hover,
.project-card a:hover,
.contacts a:hover,
.btn:hover {
  color: var(--darkcyan);
}

/* CONTACT */

.contacts {
  margin: 0 auto;
  text-align: center;
}

.contact-title {
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  padding-top: 5rem;
}

.contacts p {
  margin: 0 auto;
  line-height: 2.1;
}

.contacts .btn {
  margin-top: 3rem;
  border: 2px solid var(--cyan);
  padding: 15px;
  border-radius: 10px;
}

.contacts .btn:hover {
  transform: scale(1.1);
}

/* FOOTER */

footer {
  background: var(--darkblue);
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

footer h4 {
  color: #c4c4c4da;
  font-size: 18px;
  font-weight: lighter;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  margin: 0 auto;
  align-items: center;
  transition: all 0.5s ease-out;
}

footer h4:hover {
  color: var(--darkcyan);
}

/* Social Media Icons */

.social-container {
  width: 100%;
  margin: 1rem;
  text-align: center;
}

.social-icons {
  padding: 0;
  list-style: none;
  margin: 1em;
}

.social-icons li {
  display: inline-block;
  margin: 0.15em;
  position: relative;
  font-size: 1.2em;
}

.social-icons i {
  color: #fff;
  position: absolute;
  top: 21px;
  left: 21px;
  transition: all var(--timing) ease-out;
}

.social-icons a {
  display: inline-block;
}

.social-icons a:before {
  transform: scale(1);
  content: " ";
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: block;
  background: linear-gradient(45deg, var(--darkcyan), var(--cyan));
  transition: all var(--timing) ease-out;
}

.social-icons a:hover:before {
  transform: scale(0);
  transition: all var(--timing) ease-in;
}

.social-icons a:hover i {
  transform: scale(2.2);
  color: var(--darkcyan);
  background: -webkit-linear-gradient(45deg, var(--darkcyan), var(--cyan));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all var(--timing) ease-in;
}

.footer-text {
  font-size: 15px;
}

/* Media Query */

@media screen and (max-width: 1280px) {
  .intro {
    width: 60%;
  }

  .jobs {
    width: 80%;
  }

  .profile-pic {
    opacity: 80%;
  }
}

@media screen and (max-width: 1024px) {
  main {
    margin: 0 20px;
  }

  .intro,
  .jobs {
    width: 90%;
  }
}

@media screen and (max-width: 767px) {
  main {
    width: 100%;
    margin: 0px;
  }

  section {
    margin: 0;
    padding: 0;
  }

  .intro {
    padding-left: 5%;
  }

  p {
    font-size: 15px;
  }

  .intro h2 {
    font-size: 50px;
  }

  .intro h3,
  .title {
    font-size: 25px;
  }

  .intro-text-slider {
    color: white;
  }

  .arrow {
    padding-bottom: 0%;
  }

  .jobs {
    width: 100%;
    margin: 0 auto;
  }

  footer, .social-container {
    padding: 1rem;
    margin: 0;
  }
}