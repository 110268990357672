/* FEATURED */
.feature {
  width: 60%;
  text-align: center;
}

.feature-title {
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  padding-top: 5rem;
}

.feature-project {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.feature-project .text-container {
  flex: 1;
  padding: 20px;
  text-align: start;
}

.feature-project .card-header {
  font-size: 16px;
  color: var(--cyan);
  font-weight: lighter;
  padding-left: 1rem;
}

.feature-project .card-title {
  font-size: 25px;
  color: white;
  transition: all 0.3s ease-out;
  padding-left: 0.5rem;
  margin: 0px;
}

.feature-project a {
  color: white;
  text-decoration: none;
  transition: all 0.5s ease-out
}

.feature-project a:hover {
  color: var(--darkcyan);
}

.feature-project .card-body {
  padding: 0.5rem;
}

.feature-project .card-text {
  background-color: var(--blue);
  padding: 0.5rem;
}

.feature-project .image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-project .image-container a {
  width: 100%;
  height: auto;
}

.feature-project img {
  width: 100%;
  height: auto;
  object-fit: cover;
  opacity: 60%;
  border-radius: 5px;
  transition: all 0.5s ease;
}

.feature img:hover {
  opacity: 1;
  transform: scale(1.03);
}

.feature-skills-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.5rem;
}

.github-icon {
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 1600px) {
  .feature {
    width: 70%;
  }
}

@media screen and (max-width: 1280px) {
  .feature {
    width: 80%;
  }

  .feature img {
    width: 100%;
    height: auto;
    opacity: 85%;
  }
}

@media screen and (max-width: 1024px) {
  .feature {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .feature {
    width: 100%;
  }

  .feature-project {
    flex-direction: column;
  }

  .feature-project .image-container {
    max-width: 100%;
    padding: 10px 30px;
  }

  .feature-project img {
    height: auto;
  }
}