/* OTHER PROJECTS */
.other {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.other-title {
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  padding-top: 5rem;
}

.other-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 10px;
}

.other .project-card {
  background-color: var(--blue);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 25rem;
  height: 20rem;
  padding: 20px;
  transition: all 1s ease-out;
  display: flex;
  flex-direction: column;
}

.other .project-card a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.other .project-card:hover {
  transform: translateY(-0.1in);
  border: solid 1px var(--cyan);
}

.other .project-card a:hover {
  color: var(--darkcyan);
}

.other .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.other .card-title {
  font-size: 25px;
  color: white;
  transition: all 0.3s ease-out;
  margin: 0px;
}

.other .card-title:hover {
  color: var(--darkcyan);
}

.other .skills-container {
  margin-top: auto;
}

.other .text-skill{
  flex: 1;
  margin-right: 10px;
  align-self: center;
}

.other button {
  display: block;
  width: 200px;
  margin: 0 auto;
  margin-top: 50px;
  padding: 12px;
  font-size: 18px;
  border-radius: 5px;
  color: white;
  background-color: var(--cyan);
  border-color: var(--darkcyan);
  transition: background-color 0.3s ease;
}

.other button:hover {
  color: white;
  background-color: var(--darkcyan);
}

@media screen and (max-width: 1400px) {
  .other-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .other-container {
    grid-template-columns: 1fr;
  }
}