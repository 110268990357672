/* Intro */
.intro {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  margin: 0 auto;
  line-height: 1;
}

.intro-title {
  color: var(--cyan);
  font-weight: bold;
  font-size: 25px;
}

.intro-name {
  color: white;
  font-weight: 600;
  font-size: 70px;
}

.intro-text-slider {
  color: #c4c4c4da;
  font-weight: 600;
  font-size: 40px;
}

.intro p {
  padding: 30px 0px;
}

.text-sub {
  font-weight: 100;
  font-size: 19px;
}

.text-skill {
  margin: 0;
  font-size: 15px;
  bottom: 0;
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
}

.arrow hr {
  flex: 1;
  border: none;
  border-top: 1px solid #c4c4c4da;
  margin: 0 10px;
}

.arrow a {
  text-decoration: none;
}

.arrow i {
  font-size: 3em;
}

/* ABOUT */

section {
  margin: 0 auto;
}

.about {
  width: 45%;
}

.about-title {
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  padding: 1rem 0;
  line-height: 2;
}

.about-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  flex-wrap: wrap;
}

.about-description {
  line-height: 1.4;
  padding-left: 0;
  padding-right: 25px;
  flex: 1;
}

.about-img {
  flex: 1;
  margin: 0 auto;
  max-width: 350px;
  max-height: 350px;
  border: 5px solid var(--cyan);
  border-radius: 25px;
  object-fit: cover;
  opacity: 0.5;
  background-color: var(--blue);
  transition: opacity 1s ease;
}

.about img:hover {
  opacity: 1;
}

.code-icon {
  width: 100%;
  height: 60px;
  margin: 0.5rem;
}

.skills-title {
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  padding-top: 3rem;
}

.skills-section {
  margin-top: 20px;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.skill {
  flex: 1 1 10%;
  margin: 0 auto;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.skill-label {
  font-weight: bold;
}

@media screen and (max-width: 1800px) {
  .about {
    width: 70%;
  }
}

@media screen and (max-width: 1280px) {
  .about {
    width: 80%;
  }
}

@media (max-width: 1024px) {
  .about {
    width: 90%;
    margin: 0;
  }
}

@media (max-width: 768px) {
  .about {
    width: 100%;
    margin: 0 auto;
  }

  .about-title {
    margin: 0;
  }

  .about-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .about-description {
    padding-right: 0px;
    margin: 0 auto;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .about-img {
    max-width: 100%;
  }

  .skills-title {
    padding: 0;
    line-height: 1;
  }

  .skills-section {
    margin: 15px;
  }

  .skill {
    flex: 1 1 20%;
  }
}